import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { XIcon } from 'lucide-react';
import { Montserrat } from 'next/font/google';
import { Fragment } from 'react';
import Button from '@ui/Button';

const montserrat = Montserrat({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
});

interface MobileConfirmationModalProps {
  show: boolean;
  dismiss: () => void;
  onConfirm: () => void;
  title: string;
  helpText?: string;
}

export default function MobileConfirmationModal({
  show,
  dismiss,
  onConfirm,
  title,
  helpText,
}: MobileConfirmationModalProps) {
  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={dismiss}
      >
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="fixed inset-x-0 bottom-0 flex w-full">
            <TransitionChild
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-500"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-300 sm:duration-500"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <DialogPanel className="relative h-[50rem] w-full">
                <div
                  className={`flex h-full flex-col rounded-tl-[4rem] rounded-tr-[4rem] bg-white px-4 py-6 shadow-xl ${montserrat.className}`}
                >
                  <div className="flex w-full items-center justify-between p-6">
                    <h1 className="text-[2.8rem] font-semibold text-black">
                      {title}
                    </h1>

                    <button
                      type="button"
                      aria-label="Close modal"
                      onClick={dismiss}
                    >
                      <XIcon className="h-12 w-12 text-black" />
                    </button>
                  </div>

                  {helpText && (
                    <p className="px-6 py-8 text-[2.4rem] text-zinc-600">
                      {helpText}
                    </p>
                  )}

                  <div className="mt-auto flex justify-end gap-x-8 pb-[5rem]">
                    <button
                      type="button"
                      className="text-[2.4rem] text-black"
                      onClick={dismiss}
                    >
                      Cancel
                    </button>

                    <Button
                      type="secondary"
                      text="Confirm"
                      onClick={onConfirm}
                      className="h-[6rem] text-[2.4rem] !font-semibold"
                    />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
