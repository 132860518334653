import { db } from '@util/firebase';
import { logError } from '@util/logError';
import { arrayUnion, doc, setDoc, updateDoc } from 'firebase/firestore';
import { UpdateSearchDocumentArgs } from './search.types';

export const saveSearchQuery = async (args: UpdateSearchDocumentArgs) => {
  if (!args.uid) return null;

  const searchesRef = doc(db, 'user_searches', args.uid);

  try {
    await updateDoc(searchesRef, {
      queries: arrayUnion(args.query),
    });
  } catch (error) {
    const msg = (error as Error).message;
    if (msg.includes('No document to update')) {
      await setDoc(searchesRef, {
        queries: [args.query],
      });
    } else {
      logError(error);
    }
  }
};
