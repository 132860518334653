import { Combobox } from '@headlessui/react';
import { saveSearchQuery } from '@util/firestore/search';
import { useAuth } from 'context/AuthContext';
import { useSearch } from 'context/SearchContext';
import { useRouter } from 'next-nprogress-bar';
import Input from './Input';
import { injectWebSiteScript } from 'features/schemaMarkups';
import useNavigationEvent from '@util/hooks/useNavigationEvent';
import { useCallback } from 'react';
import { isMobile } from '@util/index';

interface SearchBoxProps {
  onBlur?: () => void;
  onFocus?: () => void;
  onMobileFocus?: () => void;
  goBack?: () => void;
  onClick?: () => void;
  onEnter?: () => void;
  onClearClick?: () => void;
  searchIconRight?: boolean;
  dismissOnBlur?: boolean;
  autoFocus?: boolean;
  className?: string;
  showSearchPreview?: boolean;
}
const SearchBox = ({
  onBlur,
  onFocus,
  goBack,
  onClick,
  onEnter,
  onClearClick,
  autoFocus,
  onMobileFocus,
  className,
  showSearchPreview,
}: SearchBoxProps) => {
  const {
    searchTerm,
    setSearchTerm,
    setShowSearchPreview,
    setShowMobileSearchbar,
  } = useSearch();

  const { userDoc } = useAuth();
  const router = useRouter();

  const navigate = (keyword: string) => {
    onBlur?.();
    setShowSearchPreview(false);

    return router.push(`/search?keyword=${keyword}`);
  };

  useNavigationEvent(
    useCallback(({ url, previousUrl, searchParams, previousSearchParams }) => {
      const handleRouteChange = () => {
        setShowSearchPreview(false);
        setShowMobileSearchbar(false);
      };

      if (
        url !== previousUrl ||
        searchParams?.get('keyword') !== previousSearchParams?.get('word')
      ) {
        handleRouteChange();
      }
    }, [])
  );

  return (
    <div className="relative !z-[90] mx-auto w-full max-w-[90rem]">
      <script
        id="websiteSchema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html:
            injectWebSiteScript({
              url: 'https://mxlocker.com/',
              searchUrlTemplate:
                'https://mxlocker.com/search?keyword={search_term_string}',
            }) ?? '',
        }}
      />
      <Combobox
        as="div"
        value={searchTerm}
        onChange={(value: string) => setSearchTerm(value)}
      >
        <div className="flex items-center gap-x-4">
          <Input
            goBack={goBack}
            bordered
            full
            autoFocus={autoFocus}
            autoComplete="off"
            id="search-input"
            searchIconRight={true}
            type="search"
            value={searchTerm}
            placeholder="Search for anything"
            showClearIcon={!!searchTerm}
            onClearClick={() => {
              setSearchTerm('');

              if (!isMobile()) {
                setShowSearchPreview(false);
              }

              onClearClick?.();
            }}
            className={`!rounded-[1.5rem] border-[1px] border-black bg-[##F1F1F1] !py-2 !pl-4 lg:mt-0 ${className}`}
            iconClassName={'!rounded-[1.5rem] !h-[2rem] w-[2rem]'}
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
            onChange={(ev) => {
              setSearchTerm(ev.target.value);
              setShowSearchPreview(!!ev.target.value);
            }}
            onKeyDown={(ev) => {
              if (ev.key == 'Enter') {
                const keyword = (ev.target as HTMLInputElement).value;

                if (keyword) {
                  // NOTE: is this the best place to put this? would be nice to have some memoization.
                  // Before searching, lets save the search term to the DB so we can log that they searched for something
                  if (!!userDoc) {
                    saveSearchQuery({
                      query: searchTerm ?? '',
                      uid: userDoc.uid,
                    });
                  }

                  navigate(encodeURIComponent(keyword));
                  onEnter?.();
                  setShowSearchPreview(false);
                  setShowMobileSearchbar(false);
                }
              }
            }}
            onFocus={() => {
              onFocus?.();

              if (onMobileFocus) {
                onMobileFocus();
                setShowSearchPreview(true);
              }
            }}
            onBlur={() => {
              onBlur?.();
            }}
            onSearchButtonClick={() => {
              if (searchTerm) {
                if (!!userDoc) {
                  saveSearchQuery({
                    query: searchTerm,
                    uid: userDoc.uid,
                  });
                }

                navigate(encodeURIComponent(searchTerm));
                onEnter?.();
                setShowSearchPreview(false);
                setShowMobileSearchbar(false);
              }
            }}
          />

          {isMobile() && showSearchPreview && (
            <button
              type="button"
              className="pr-4 text-[1.6rem] font-medium text-zinc-700"
              aria-label="cancel search"
              onClick={() => {
                setSearchTerm('');
                setShowSearchPreview(false);
                onClearClick?.();
              }}
            >
              Cancel
            </button>
          )}
        </div>
      </Combobox>
    </div>
  );
};

export default SearchBox;
