'use client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AttributionProvider from 'context/AttributionContext';
import AuthProvider from 'context/AuthContext';
import { ChatProvider } from 'context/ChatContext';
import { ConfirmationModalProvider } from 'context/ConfirmationModalContext';
import { NotificationProvider } from 'context/NotificationContext';
import ProgressBarProvider from 'context/ProgressBarProvider';
import { SearchProvider } from 'context/SearchContext';
import { ShoppingCartProvider } from 'context/ShoppingCartContext';
import { StripeProvider } from 'context/StripeContext';
import { ToastProvider } from 'context/ToastContext';
import { WelcomeBackModal } from 'features/welcomeBack';
import { ReactNode } from 'react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});
// PUT ALL OF YOUR CONTEXT PROVIDERS HERE
function ProviderProvider({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AttributionProvider>
        <ProgressBarProvider>
          <SearchProvider>
            <AuthProvider>
              <StripeProvider>
                <ToastProvider>
                  <ShoppingCartProvider>
                    <NotificationProvider>
                      <ChatProvider>
                        <ConfirmationModalProvider>
                          {children}
                        </ConfirmationModalProvider>
                        <WelcomeBackModal />
                      </ChatProvider>
                    </NotificationProvider>
                  </ShoppingCartProvider>
                </ToastProvider>
              </StripeProvider>
            </AuthProvider>
          </SearchProvider>
        </ProgressBarProvider>
      </AttributionProvider>
    </QueryClientProvider>
  );
}

export default ProviderProvider;
